export class Tabs {
    constructor() {
        const tabsBlock = document.querySelector('.tabstextimage');

        if (tabsBlock) {
            const tabs = tabsBlock.querySelectorAll('.repeater_tab');
            const tabsContent = tabsBlock.querySelectorAll('.textimage');

            tabs.forEach((tab, index) => {
                tab.addEventListener('click', () => {
                    tabs.forEach(tab => tab.classList.remove('current'));
                    tabsContent.forEach(tabContent => tabContent.classList.remove('current'));
                    tab.classList.add('current');
                    tabsContent[index].classList.add('current');
                });
            });
        }
    }
}
