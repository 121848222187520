export class Lightbox {
    constructor() {
        const imagesSlide = document.querySelectorAll('.custom_swiper .image')
        const modal = document.getElementById('modal-lightbox')
        const close = document.querySelector('.close')
        this.imagesLightbox = document.querySelectorAll('.lightbox')
        const boxLenght = this.imagesLightbox.length - 1
        const prev = document.querySelector('.modal-lightbox .prev')
        const next = document.querySelector('.modal-lightbox .next')
        let numPosition = 0
        const that = this;

        if (imagesSlide.length) {
            imagesSlide.forEach(function (e) {
                e.addEventListener('click', function (el) {
                    el.preventDefault()
                    modal.style.display = 'block'

                    that.setActive(el)
                })
            })

            close.addEventListener('click', function () {
                modal.style.display = 'none'
            })

            prev.addEventListener('click', function () {
                that.prev()
            })

            next.addEventListener('click', function () {
                that.next(boxLenght)
            })
        }
    }

    setActive(n) {
        this.imagesLightbox.forEach(function (box) {
            box.classList.remove('active')

            if (box.querySelector('img').getAttribute('src') === n.target.getAttribute('src')) {
                box.classList.add('active')
            }
        })
    }

    prev() {
        let numPosition = 0;
        this.imagesLightbox.forEach(function (box) {
            if (box.classList.contains('active')) {
                numPosition = parseInt(box.getAttribute('data-position'))
                if (numPosition !== 0) {
                    box.classList.remove('active')

                }
            }
        })
        this.imagesLightbox.forEach(function (box) {
            if (numPosition > 0 && parseInt(box.getAttribute('data-position')) === (numPosition - 1)) {
                box.classList.add('active')
            }
        })
    }

    next(boxLenght) {
        let numPosition = 0;
        this.imagesLightbox.forEach(function (box) {
            if (box.classList.contains('active')) {
                numPosition = parseInt(box.getAttribute('data-position'))
                if (numPosition < boxLenght) {
                    box.classList.remove('active')
                }
            }
        })
        this.imagesLightbox.forEach(function (box) {
            if (numPosition < boxLenght && parseInt(box.getAttribute('data-position')) === (numPosition + 1)) {
                box.classList.add('active')
            }
        })
    }
}
