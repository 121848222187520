export class Header {
    constructor() {
        const banner = document.querySelector('.banner')

        if (banner) {
            window.addEventListener('scroll', function () {
                setTimeout(function () {
                    if (window.scrollY > 80 && !banner.classList.contains('is-scroll')) {
                        banner.classList.add('is-scroll')
                    }
                    if (window.scrollY <= 80) {
                        banner.classList.remove('is-scroll')
                    }
                }, 200)
            })
        }
    }
}
