import Swiper from "swiper";
import {Navigation} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

export class Slider {

    constructor() {

        new Swiper('.swiper_textimage', {
            slidesPerView: 1,
            spaceBetween: 30,
            slidesPerGroup: 1,
            modules: [Navigation],
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }
        })
    }
}
