export class Parallax {
    constructor() {
        window.addEventListener('scroll', throttle(parallax() ,14));

        function throttle(fn, wait) {
            var time = Date.now();
            return function () {
                if ((time + wait - Date.now()) < 0) {
                    parallax();
                    time = Date.now()
                }
            }
        }

        function parallax() {
            const overlayHeader = document.querySelectorAll('[data-parallax]');
            var scrolled = window.pageYOffset;
            if (overlayHeader != null) {
                var delay = 0.35;
                var coords = (scrolled * delay) + 'px';
                var opacity = (1 - (scrolled/100) / 3);

                overlayHeader.forEach(function (e) {
                    e.style.transform = 'translateY(' + coords + ')';
                    e.style.opacity = opacity
                })
            }
        }
    }
}
